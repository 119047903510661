@import url('https://fonts.googleapis.com/css2?family=Suwannaphum:wght@100;300;400;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Kantumruy+Pro:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');

body {
  background: #0f172a !important;
  font-family: 'Kantumruy Pro', sans-serif !important;
}

.konFont {
  font-family: 'Kantumruy Pro', sans-serif !important;
}

.player-wrapper {
  position: relative;
  padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}

.video-js .vjs-menu-button-popup .vjs-menu {
  left: auto;
  right: 0;
}

/* loadingPage */
.loadingText {
  color: rgb(4, 138, 179);
}
.lds-facebook {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-facebook div {
  display: inline-block;
  position: absolute;
  left: 8px;
  width: 16px;
  background: rgb(4, 138, 179);
  animation: lds-facebook 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}
.lds-facebook div:nth-child(1) {
  left: 8px;
  animation-delay: -0.24s;
}
.lds-facebook div:nth-child(2) {
  left: 32px;
  animation-delay: -0.12s;
}
.lds-facebook div:nth-child(3) {
  left: 56px;
  animation-delay: 0;
}
@keyframes lds-facebook {
  0% {
    top: 8px;
    height: 64px;
  }
  50%,
  100% {
    top: 24px;
    height: 32px;
  }
}

.Layout-sc-nxg1ff-0.fmjCoJ.top-bar {
  display: none !important;
}

/* all scroll */
*::-webkit-scrollbar {
  width: 5px;
}

*::-webkit-scrollbar-thumb {
  /* border-radius: 5px; */

  background: rgba(150, 150, 150, 0.479);
}
/* scroll */
.scrollComment::-webkit-scrollbar {
  width: 5px;
}

/* .scrollComment::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgb(133, 133, 133);
  border-radius: 5px;
} */

.scrollComment::-webkit-scrollbar-thumb {
  /* border-radius: 5px; */

  background: rgba(150, 150, 150, 0.479);
}

/* video player */

/* #videoElement::-webkit-media-controls-fullscreen-button {
  display: none !important;
} */
#videoElement::-webkit-media-controls-timeline {
  display: none;
}
/* #videoElement::-webkit-media-controls-time-remaining-display {
  display: none;
} */
/* #videoElement::-webkit-media-controls-current-time-display {
  display: none;
} */

#liveVideo::-webkit-media-controls-timeline {
  display: none;
}
#liveVideo::-webkit-media-controls-time-remaining-display {
  display: none;
}
#liveVideo::-webkit-media-controls-current-time-display {
  display: none;
}

#videoIframe::-webkit-media-controls-fullscreen-button {
  /* display: none !important; */
}
#videoIframe::-webkit-media-controls-timeline {
  display: none;
}
#videoIframe::-webkit-media-controls-time-remaining-display {
  display: none;
}
#videoIframe::-webkit-media-controls-current-time-display {
  display: none;
}

/* #0F172A  main color */
.bg_matchCom {
  background: #1e293b;
}
/* world cup */
#world_cup td {
  border-color: #00aaff !important;
}
#world_cup tr {
  height: 10px;
}
#world_cup td {
  padding: 5px 10px;
  width: 10px;
  /* border-radius: 5px; */
}
#world_cup .bg {
  background: #1e293b;
  width: 200px;
  color: white;
}
#world_cup .bg_n {
  background: #00aaff;
  font-weight: 600;
  color: #000;
}
#world_cup .bg_f {
  background: #8610103f;
  font-weight: 300;
  color: rgba(255, 255, 255, 0.514);
}

/* width */
.w22Scroll::-webkit-scrollbar {
  width: 1px;
  height: 6px;
}

/* Track */
.w22Scroll::-webkit-scrollbar-track {
  background: #1e293b;
}

/* Handle */
.w22Scroll::-webkit-scrollbar-thumb {
  background: rgba(150, 150, 150, 0.479);
}

/* width */
.adminNav::-webkit-scrollbar {
  width: 5px;
  height: 8px;
}

/* Track */
.adminNav::-webkit-scrollbar-track {
  background: #1e293b;
}

/* Handle */
.adminNav::-webkit-scrollbar-thumb {
  background: #5d7bac;
}

/* Handle on hover */
/* #w22Scroll::-webkit-scrollbar-thumb:hover {
  background: #555;
} */

.ck.ck-content {
  background: #1e293b !important;
  font-family: 'Suwannaphum', 'Roboto Condensed', sans-serif;
}

h1 {
  font-size: 20px !important;
}

.custom-img {
  object-fit: contain;
  width: 100% !important;
  position: relative !important;
}

.unset-img {
  width: 100%;
}
.unset-img > div {
  position: unset !important;
}

h1 {
  font-size: 2.25em;
}
h2 {
  font-size: 1.5em;
}
h3 {
  font-size: 1.17em;
}
h4 {
  font-size: 18px;
}

figure.image {
  width: 100%;
  text-align: center;
}

figure.image img {
  width: 100%;
}

.ck {
  font-family: 'Kantumruy Pro', sans-serif !important;
}

.ck-content a {
  color: blue;
  text-decoration: underline;
}

ul {
  list-style-type: disc;
  list-style-position: inside;
}
ol {
  list-style-type: decimal;
  list-style-position: inside;
}
ul ul,
ol ul {
  list-style-type: circle;
  list-style-position: inside;
  margin-left: 15px;
}
ol ol,
ul ol {
  list-style-type: lower-latin;
  list-style-position: inside;
  margin-left: 15px;
}

.mx-line-1 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
  line-clamp: 1;
  -webkit-box-orient: vertical;
}

.mx-line-2 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

::-webkit-calendar-picker-indicator {
  background: url('https://cdn3.iconfinder.com/data/icons/linecons-free-vector-icons-pack/32/calendar-16.png')
    center/80% no-repeat;
  color: bla;
}
